((fn) => {
  if (typeof document !== 'undefined') {
    if (document.readyState !== 'loading') {
      fn();
    } else {
      document.addEventListener('DOMContentLoaded', fn);
    }
  }
})(() => {
  require('./clientInner');
});
