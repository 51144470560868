/* eslint-disable padding-line-between-statements */
import React from 'react';
import { InstanceProvider, ThemeProvider } from '@stepstone-genesis/components';
import {
  LocaleProvider,
  PageContextParams,
  PlatformSiteProvider,
} from '@stepstone-platform/platform-apis';
import { TranslationsProvider } from '@stepstone-platform/i18n/compat';
import * as Dresscode from '@stepstone-genesis/dresscode';
import translations from '@stepstone-fragment/login-registration/translations.json';
import genesisInstance from 'genesis-instance';

type Props = {
  children: React.ReactNode;
  context: PageContextParams;
  themeId: Dresscode.ThemeBrandKeys;
};
export function EndpointProviders({ children, context, themeId }: Props) {
  const [cache] = React.useState(() => genesisInstance.createCache());

  return (
    <InstanceProvider instance={genesisInstance} cache={cache}>
      <ThemeProvider theme={Dresscode[themeId]}>
        <PlatformSiteProvider platformSite={context.platformSite}>
          <LocaleProvider locale={context.locale}>
            <TranslationsProvider
              platformSite={context.platformSite}
              translationsJSON={translations}
              defaultLocale={context.locale}
            >
              {children}
            </TranslationsProvider>
          </LocaleProvider>
        </PlatformSiteProvider>
      </ThemeProvider>
    </InstanceProvider>
  );
}
