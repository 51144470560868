import React from 'react';
import { createRoot } from 'react-dom/client';
// eslint-disable-next-line no-restricted-imports
import { EndpointProviders } from 'modules/server/controllers/endpoint/endpoint-providers';
import { EndpointClient } from 'types/endpoint';
import { FragmentRenderState } from 'types/fragment-state';

export function renderEndpoint(endpoint: EndpointClient) {
  const containerId = `app-${endpoint.name}`;
  const element = document.getElementById(containerId);
  const state: FragmentRenderState = window.__PRELOADED_STATE__[endpoint.name];
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const root = createRoot(element!);

  if (element && state) {
    root.render(
      <EndpointProviders context={state.props.context} themeId={state.themeId}>
        <endpoint.container {...state.props} />
      </EndpointProviders>,
    );
  }
}
